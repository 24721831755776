.footer {
    font-family: Univers Roman;
}

.cmp-footer {
    padding: 70px 0;
    background: #1d1d1b;
    border-top: 8px solid #222;
    font-size: 14px;
    color: #fff
}

@media screen and (max-width:767px) {
    .cmp-footer {
        padding: 20px 0
    }
}

.cmp-footer__link {
    color: #fff;
    text-decoration: none
}

.cmp-footer__container {
    max-width: 77pc;
    margin: 0 auto;
    padding: 0 32px
}

.cmp-footer__container .cmp-footer__logo-wrapper {
    border-bottom: 1px solid #4d4d4d;
    padding: 20px 0 30px;
    margin-bottom: 31px
}

@media screen and (max-width:767px) {
    .cmp-footer__container {
        padding: 0 20px;
        margin-bottom: 20px
    }
}

.cmp-footer__category.active .cmp-footer__headline:before {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg)
}

.cmp-footer__category.active .cmp-footer__links {
    display: block
}

.cmp-footer__category .cmp-footer__headline {
    position: relative;
    display: block;
    font-size: 16px;
    font-family: 'Univers Roman';
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #fff;
    margin: 0 0 10px
}

@media screen and (max-width:768px) {
    .cmp-footer__category .cmp-footer__headline {
        margin: 0;
        cursor: pointer;
        padding: 13px 47px 12pt 8px
    }

    .cmp-footer__category .cmp-footer__headline:before {
        display: block;
        position: absolute;
        padding: 5px;
        right: 20px;
        border: 1px solid #fff;
        border-width: 0 2px 2px 0;
        content: "";
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -webkit-transition: -webkit-transform .3s ease-in-out;
        transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out
    }
}

.cmp-footer__category .cmp-footer__links {
    list-style: none;
    padding: 0;
    margin: 0
}

.cmp-footer__category .cmp-footer__links li {
    padding-bottom: 10px
}

@media screen and (max-width:768px) {
    .cmp-footer__category .cmp-footer__links {
        padding-left: 8px;
        display: none
    }
}

.cmp-footer__category .cmp-footer__links .cmp-footer__link {
    height: 24px;
    -webkit-text-decoration-line: none;
    text-decoration-line: none;
    opacity: 0.8;
    font-family: 'Univers Roman';
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.2px;
    color: #ececec;
    padding-bottom: 3px;
    border-bottom: 1px solid transparent
}

.cmp-footer__category .cmp-footer__links .cmp-footer__link:hover {
    border-color: #e20613
}

@media screen and (max-width:767px) {
    .cmp-footer__category .cmp-footer__links .cmp-footer__link:hover {
        border-color: transparent
    }
}

.cmp-footer__category-wrapper {
    margin: 0 -16px
}

.cmp-footer__category-wrapper .social-media__container {
    padding-right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

@media screen and (max-width:768px) {
    .cmp-footer__category-wrapper .social-media__container {
        margin-top: 20px;
        -webkit-box-pack: left;
        -ms-flex-pack: left;
        justify-content: left
    }
}

.cmp-footer .cmp-footer__bottom {
    border-top: 1px solid #4d4d4d;
    line-height: 24px;
    margin: 30px 0 0;
    overflow: hidden;
    padding: 30px 0;
    text-align: center
}

.cmp-footer .cmp-footer__bottom .cmp-footer__left {
    float: left
}

@media screen and (max-width:767px) {
    .cmp-footer .cmp-footer__bottom .cmp-footer__left {
        float: none;
        padding: 13px 0 0
    }
}

.cmp-footer .cmp-footer__bottom .cmp-footer__left .cmp-footer__links {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.cmp-footer .cmp-footer__bottom .cmp-footer__left .cmp-footer__links li {
    margin: 0 25px 10px 0
}

@media screen and (max-width:1024px) {
    .cmp-footer .cmp-footer__bottom .cmp-footer__left .cmp-footer__links li {
        margin: 0 0 10px
    }
}

.cmp-footer .cmp-footer__bottom .cmp-footer__left .cmp-footer__links .footer-link {
    height: 24px;
    -webkit-text-decoration-line: none;
    text-decoration-line: none;
    opacity: 0.8;
    font-family: 'Univers Roman';
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.2px;
    color: #b2b2b1;
    border-bottom: 1px solid transparent;
    padding: 0
}

.cmp-footer .cmp-footer__bottom .cmp-footer__left .cmp-footer__links .footer-link:hover {
    border-color: #e20613
}

@media screen and (max-width:1024px) {
    .cmp-footer .cmp-footer__bottom .cmp-footer__left .cmp-footer__links .footer-link {
        padding-right: 16px
    }

    .cmp-footer .cmp-footer__bottom .cmp-footer__left .cmp-footer__links .footer-link:hover {
        border-color: transparent
    }
}

@media screen and (max-width:767px) {
    .cmp-footer .cmp-footer__bottom .cmp-footer__left .cmp-footer__links .footer-link {
        padding-left: 16px
    }
}

.cmp-footer .cmp-footer__bottom .cmp-footer__right {
    float: right
}

@media screen and (max-width:767px) {
    .cmp-footer .cmp-footer__bottom .cmp-footer__right {
        float: none;
        padding: 0 47px 12pt 8px
    }
}

.cmp-footer .cmp-footer__bottom .cmp-footer__right .cmp-footer__copyright {
    height: 24px;
    padding: 0;
    margin: 0;
    opacity: 0.8;
    font-family: 'Univers Roman';
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.2px;
    color: #b2b2b1
}

@media screen and (max-width:767px) {
    .cmp-footer .cmp-footer__bottom .cmp-footer__right .cmp-footer__copyright {
        text-align: left;
        padding-top: 20px;
        padding-left: 8px
    }
}

.cmp-footer .cmp-footer__bottom.no-border {
    border-top: none;
    margin: 0;
    padding: 0 0 30px
}

.cmp-footer__upper__text {
    text-align: center;
    margin-bottom: 15px;
}

.hide-cmp-footer__upper__text {
    display: none;
}