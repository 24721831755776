.btn-back {
    border: none;
    background-color: white;
    position: relative;
}

.btn-back-offset {
    margin-top: -0.5em;
    margin-left: -8em;
}

.btn-continue {
    background-color: #E20613;
    color: white;
    padding: 15px 25px;
    border: none;
    font-family: Univers B;
}

.btn-continue:hover {
    background-color: #E20613;
    color: white;
}

.btn-continue:focus {
    background-color: #E20613;
    color: white;
}

.btn-continue:disabled {
    background-color: #EDEBE9;
    color: #605E5C80;
}

.grey-box-alt {
    background-color: #ebebeb;
    margin-left: -25px;
    display: flex;
    align-items: center;
    gap: 15px;
}

.footnote {
    background-color: #faf5f5;
    border-radius: 5px;
}

/* responsive */

@media only screen and (max-width: 395px) {
    .btn-continue {
        padding-top: 20px;
    }
}

@media only screen and (max-width: 660px) {
    .btn-back-offset {
        margin-left: 0em;
    }
}