.modal-popup {
    cursor: pointer;
    width: 25px;
}

.modal-content {
    font-family: 'Univers Roman';
    border-radius: 0.3rem;
}

.modal-info-icon {
    width: 40px;
    margin-top: -7px;
}

.move-info-top {
    margin-top: -0.3em;
}

.modal-header {
    position: relative;
    border-bottom: none;
}

.modal-header .btn-close {
    position: absolute;
    right: 15px;
    top: 15px;
}

.modal-title {
    font-family: Univers B;
    font-size: 16px;
}

.modal-footer {
    border-top: none;
}

.modal-footer .btn-continue {
    padding: 10px 20px;
}