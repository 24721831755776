.choice-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 60px;
    min-height: 116px;
}

.choice-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.choice-item img {
    /* width: 50px; */
    height: 50px;
}

input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.1em solid #c6c6c6;
    border-radius: 50%;
    display: grid;
    place-content: center;
}

input[type="radio"]::before {
    content: "";
    width: 0.4em;
    height: 0.4em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #E20613;
}

input[type="radio"]:checked::before {
    transform: scale(1);
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.form-control:focus,
.unit .btn {
    box-shadow: none;
}

.grid-row {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    gap: 5px;
}

.unit.btn-group {
    margin-left: -5.2em;
}

.unit {
    margin-left: -1.3em;
}

.unit-item {
    background-color: #EDEBE9;
    border-radius: 0px;
    padding: 8px;
    cursor: pointer;
    color: #5e5b59;
}

.unit-item:hover {
    color: black;
}


.unit-meter {
    margin-left: -1.8em;
}

.unit-kwh {
    margin-left: -2.4em;
}

.unit-g-kwh {
    margin-left: -3.8em;
    background-color: white;
    padding-left: 5px;
}

.disabled {
    pointer-events: none;
}

.block-4 {
    margin-bottom: 10px;
}

.block-4 .form-check-label span {
    display: inline-block;
}

input[type=checkbox]:checked {
    background-color: #e20613 !important;
    border-color: #e20613 !important;
}

.active-unit {
    background-color: #E20613 !important;
    color: white;
    border-left: none !important;
}

.active-unit:hover {
    color: white;
}

.consent-text {
    font-size: 10px;
}

.email-img-content {
    position: relative;
}

.icon-email {
    position: absolute;
    width: 100px;
    top: -5px;
    left: -30px;
}

.image-email {
    width: 100%;
    border-radius: 0px 0px 50px 0px;
}

.white-box {
    background-color: white;
    min-height: 110px;
    border-radius: 5px;
}

.report-section .hstack {
    justify-content: space-between;
    align-items: end;
}

.report-section .choice-text {
    margin-bottom: 0px;
}

.blue-border {
    border: 2px solid rgba(0, 155, 180, 1);
    border-radius: 5px;
}

.blue-border-dashed {
    border: 2px dashed rgba(0, 155, 180, 1);
    border-radius: 5px;
}

.dot {
    height: 15px;
    width: 15px;
    background-color: #bbb;
    border-radius: 50%;
    display: block;
}

.landlord-dot {
    background-color: rgba(0, 155, 180, 1);
}

.landlord-text {
    color: rgba(0, 155, 180, 1);
    white-space: nowrap;
}

.tenant-dot {
    background-color: rgba(0, 155, 180, 0.55);
}

.tenant-text {
    color: rgba(0, 155, 180, 0.55);
    white-space: nowrap;
}

.black-text {
    color: black;
}

.btn-heizung,
.btn-heizung:active,
.btn-heizung:hover {
    background-color: rgba(0, 155, 180, 1) !important;
    color: white;
    border: none;
    box-shadow: none;
    height: fit-content;
}

.cross-text {
    text-decoration: line-through;
}

.discount-text {
    color: #E20613;
}

.extra-space {
    padding-bottom: 120px;
}

.move-top {
    margin-top: -90px;
}

.hidden-element {
    display: none !important;
}

.fs-22 {
    font-size: 22px;
}

.fs-40 {
    font-size: 40px;
}

.mw-880px {
    max-width: 880px !important;
}

#bar-canvas {
    height: 400px;
}

/* final page style */

.grey-box {
    position: relative;
    background-color: #d9d9d980;
    border-radius: 50px;
}

.bordered-img {
    width: 100%;
    border-radius: 50px 0px;
}

.h-480 {
    height: 480px;
}

.grey-text {
    color: #4c4c4c;
    line-height: 1.75;
}

.light-grey-text {
    color: #878786;
    font-size: 25px;
}

.page-2,
.page-3 {
    position: relative;
    height: 1460px;
    margin: 0px 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.pdf-front-headline {
    position: absolute;
    bottom: 60px;
    left: 40px;
    color: white;
}

.pdf-front-headline hr {
    color: #E20613;
    border-top: 3px solid;
}

.pdf-front-img {
    position: absolute;
    bottom: -30px;
    right: 50px;
}

.page-2 hr {
    border-top: 3px solid #E20613;
}

.grey-background {
    background-color: #F4F6F8;
}

.techem-footer,
.grey-background {
    margin: 0px -100px;
}

.full-width {
    width: 910px;
    height: 1200px;
}

#pdf-section {
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    display: none;
    min-width: 1200px;
}

.red-text {
    color: #E20613;
}

.mb-22 {
    margin-bottom: 22px;
}

/* responsive styles */
@media (max-width: 575px) {
    .choice-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .consent-check .form-check-input {
        width: 30px;
        height: 30px;
    }

    .remove-font .fs-40 {
        font-size: 40px;
    }
}

@media (min-width: 575px) {
    .mb-22 {
        margin-bottom: 30px;
    }
}

@media (min-width: 992px) {
    .white-box {
        min-height: 160px;
    }
}

@media (max-width: 992px) {
    .p-0__mb-3__custom {
        padding: 0px !important;
        margin-bottom: 15px;
    }
}