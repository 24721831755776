.header__components {
	position: relative;
	height: 84px;
	max-width: 77pc;
	margin: 0 auto;
	padding: 0 1em;
	top: 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
  }
  
  .header__logo-image {
	height: 38px;
	width: 112px;
	cursor: pointer;
	-o-object-fit: cover;
	object-fit: cover;
	vertical-align: middle;
  }
  
 .text-stage-content {
	position: relative;
	border-bottom: 6px solid #e20613
  }

  .hide-header {
	display: none;
  }

  .move-bot{
	top: 30em;
	z-index: 1;
  }
